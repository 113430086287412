import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { LabelsContainer } from '@vancity/react-component-library';
import IFrame from '../../Common/IFrame';
import device from '../../../Common/Device';
import Link from '../../../Common/Link';

const Container = styled.div`
  padding: 40px 80px;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  z-index: 99;
  position: relative;
  margin-top: -4.75rem;
  text-align: left;
  display: flex;
  flex: 1;
  flex-direction: column;

  p {
    margin: 0 0 16px 0;
  }

  h4 {
    margin: 0;
  }

  @media ${device.max.md} {
    margin-top: -6rem;
  }

  @media ${device.max.sm} {
    padding: 40px 40px;
  }

  @media ${device.max.xs} {
    padding: 40px 24px;
  }
`;

const StyledLabelsContainer = styled(LabelsContainer)`
  margin-bottom: 16px !important;

  p {
    margin: 0;
  }
`;

const LinkContainer = styled.div`
  display: inline-flex;
  margin-top: 16px;

  a {
    text-decoration: underline;
  }
`;

const Outer = styled.div`
  background: #f7f7f7;
`;

const StyledRow = styled(Row)`
  justify-content: center;
  text-align: center;
`;

export default ({
  episode,
  episodeDate,
  title,
  description,
  embedSrc,
  cta,
  ctaUrl
}) => {
  return (
    <Outer>
      <div className="container-lg">
        <StyledRow>
          <Col lg={10} xs={12}>
            <Container>
              <StyledLabelsContainer
                firstText={episode}
                secondText={episodeDate}
                highlightFirstText
              />
              <h4>{title}</h4>
              <p>{description}</p>
              <IFrame title={title} src={embedSrc} />
              <LinkContainer>
                <Link to={ctaUrl}>{cta}</Link>
              </LinkContainer>
            </Container>
          </Col>
        </StyledRow>
      </div>
    </Outer>
  );
};
