import React from 'react';
import Sections from '../../Common/Sections';
import Hero from '../Common/Hero/Hero';
import CtaSection from './CtaSection/CtaSection';
import EpisodeListSection from './EpisodeListSection/EpisodeListSection';
import LatestEpisode from './LatestEpisode/LatestEpisode';

export default ({ data }) => {
  return (
    <>
      <Hero
        logoImg={data.podcastImage.fixed}
        logoTitle={data.podcastImage.title}
        header={data.header}
        subheader={data.subHeader}
        image={data.heroImage.fluid}
        spotifyUrl={data.spotifyLink}
        appleUrl={data.appleLink}
        googleUrl={data.googleLink}
        isContentCenter
      />
      <LatestEpisode
        episode="Latest episode"
        episodeDate={data.episodeList[0].episodeDate}
        title={data.episodeList[0].episodeTitle}
        description={data.episodeList[0].episodeShortDescription}
        embedSrc={data.episodeList[0].embedSource}
        cta="Episode details"
        ctaUrl={data.episodeList[0].pagePath}
      />
      <Sections>
        <CtaSection
          header={data.promotion.header}
          body={data.promotion.body}
          primaryCta={data.promotion.ctaText}
          primaryCtaUrl={data.promotion.ctaLink.url}
        />
        <EpisodeListSection
          header="Previous episodes"
          items={data.episodeList.slice(1)}
        />
      </Sections>
    </>
  );
};
