import React from 'react';
import { Row, Col } from 'reactstrap';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import styled from 'styled-components';
import Section from '../../../Common/Section';
import LinkButton from '../../../Common/Buttons/LinkButton';

const StyledRow = styled(Row)`
  justify-content: center;
  text-align: center;
`;

const CtaContainer = styled.div`
  margin-top: 80px;
`;

const StyledSection = styled(Section)`
  overflow: visible;
  margin-top: -6.25rem;
`;

export default ({ header, body, primaryCta, primaryCtaUrl }) => {
  return (
    <>
      <StyledSection>
        <div className="container-lg">
          <StyledRow>
            <Col lg={6} md={7} xs={12}>
              <CtaContainer>
                <h4>{header}</h4>
                <p>{body && renderRichText(body)}</p>
                <LinkButton to={primaryCtaUrl}>{primaryCta}</LinkButton>
              </CtaContainer>
            </Col>
          </StyledRow>
        </div>
      </StyledSection>
    </>
  );
};
