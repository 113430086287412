import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { Card } from '@vancity/react-component-library';
import Section from '../../../Common/Section';
import SectionHeader from '../../../Common/Header/SectionHeader';

const Item = styled(Card)`
  padding: 40px 0 !important;
  box-shadow: none !important;

  h5,
  p {
    margin: 0 !important;
  }

  div {
    a {
      text-decoration: underline;
    }
    :last-child {
      margin-top: 16px !important;
    }
  }

  :not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }

  :first-child {
    padding: 0 0 40px !important;
  }

  :last-child {
    padding: 40px 0 0 !important;
  }
`;

export default ({ header, items }) => {
  return (
    <Section>
      <div className="container-lg">
        <SectionHeader header={header} />
        <Row>
          <Col lg={8} md={{ size: 8, offset: 2 }} xs={12}>
            {items.map(item => (
              <Item
                firstText={`Episode ${item.episodeNumber}`}
                secondText={item.episodeDate}
                title={item.episodeTitle}
                description={item.episodeShortDescription}
                cta={`Episode ${item.episodeNumber} details`}
                ctaUrl={item.pagePath}
              />
            ))}
          </Col>
        </Row>
      </div>
    </Section>
  );
};
