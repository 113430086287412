import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/seo';
import Podcast from '../../components/Podcast/Landing/Podcast';

const PodcastPage = () => {
  const result = useStaticQuery(graphql`
    query {
      contentfulSpecialPodcastLanding(
        contentful_id: { eq: "2mJCvtu5BWCYUvR4JhKLea" }
      ) {
        metaTitle
        metaDescription
        metaKeywords
        pagePath
        heroImage {
          fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyContentfulFluid
          }
        }
        podcastImage {
          title
          fixed(quality: 90, width: 80) {
            ...GatsbyContentfulFixed
          }
        }
        header
        subHeader
        spotifyLink
        appleLink
        googleLink
        promotion {
          header
          body {
            raw
          }
          ctaText
          ctaLink {
            url
          }
        }
        episodeList {
          episodeDate(formatString: "MMM DD, YYYY")
          episodeNumber
          episodeTitle
          episodeShortDescription
          embedSource
          pagePath
        }
      }
    }
  `);

  const data = result.contentfulSpecialPodcastLanding;

  return (
    <Layout currentPage="Podcast">
      <SEO
        title={data.metaTitle}
        description={data.metaDescription}
        keywords={data.metaKeywords.split(', ')}
      />
      <Podcast data={data} />
    </Layout>
  );
};

export default PodcastPage;
